import sendEventDataGql from "@/queries/mutations/sendEventData.gql";

export const actions = {
    async sendEventData({ commit, dispatch, rootState }, { eventData }) {
        const { userAgent, userIpAddress, currentUrl } = rootState.userInfo;
        eventData.userData = {
            ...eventData.userData,
            ip: userIpAddress,
            ua: userAgent,
        };

        const client = this.app.apolloProvider.defaultClient;

        let isConsented = this.$storage.getCookie('CookieInformationConsent') ?? null;
        if (!isConsented) {
            return;
        }

        try {
            const {
                data: {
                    sendEventData: { success, error_message, api_data },
                },
            } = await client.mutate({
                mutation: sendEventDataGql,
                variables: { data: eventData },
            });

            if (!success) {
                console.error(error_message);
            }

            if (this.$gtm) {
                if (eventData.eventName === 'Purchase' || eventData.eventName === 'InititateCheckout') {
                    fbq('track', eventData.eventName, api_data, {eventId: eventData.eventId});
                } else {
                    fbq('track', eventData.eventName, eventData.eventData, {eventId: eventData.eventId});
                }
            }
        } catch (e) {
            console.error(e.message);
        }
    },
};
