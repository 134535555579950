export function createEventData(eventName, url, eventData = {}, userData = {}) {
    eventName = eventName ?? "PageView";
    url = url ?? window.location.href;

    let uCookie = document.cookie;
    let uHash = stringToHash(uCookie);
    let urlHash = stringToHash(url);
    let timestamp = String(Date.now());

    return {
        eventName: eventName,
        eventId: eventName + '-' + uHash + urlHash + timestamp,
        eventData,
        userData: {
            ...userData,
        },
        url: url
    };
}

export function stringToHash(string) {
    let hash = 0;
    let stringLength = string.length;
    let i;
    let char;

    if (stringLength === 0) {
        return hash;
    }

    for (i = 0; i < stringLength; i += 1) {
        char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return String(hash);
}
